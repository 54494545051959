<style scoped>
.ck {
  cursor: pointer;
}
</style>
<template>
  <div class="vue-box">
    <div class="c-panel">
      <!-- 参数栏 -->
      <div class="c-title">通道报表</div>
      <el-form :inline="true" size="mini">
        <el-form-item label="日期:">
          <el-select :popper-append-to-body="true" v-model="p.type" @change="f5">
            <el-option label="今日" :value="1"></el-option>
            <el-option label="昨天" :value="2"></el-option>
            <el-option label="近7天" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商户号:">
          <el-input v-model="p.mchId" placeholder="精确查找" clearable></el-input>
        </el-form-item>
        <el-form-item label="码队商户号:">
          <el-input v-model="p.codeMchId" placeholder="精确查找" clearable></el-input>
        </el-form-item>
        <el-form-item style="margin-left: 50px">
          <el-button type="primary" icon="el-icon-search" @click="
              p.page = 1;
              f5();
            ">查询</el-button>
          <el-button type="success" icon="el-icon-refresh" @click="
              p.page = 1;
              f5();
            ">刷新</el-button>
        </el-form-item>
        <br />
      </el-form>
      <el-table :data="dataList" size="mini" :key="conditionsKey" :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }">
        <el-table-column label="通道代码" prop="date" width="200px">
          <template slot-scope="s">
            <el-link style="margin-right: 5px; font-size: 14px">{{s.row.payType}}</el-link>
          </template>
        </el-table-column>
        <el-table-column label="通道名称" prop="date" width="200px">
          <template slot-scope="s">
            <el-link v-if="s.row.payType == 1" size="info">微信H5</el-link>
            <el-link v-if="s.row.payType == 2" size="info">微信扫码</el-link>
            <el-link v-if="s.row.payType == 3" size="info">直付通H5</el-link>
            <el-link v-if="s.row.payType == 4" size="info">电商通内付</el-link>
            <el-link v-if="s.row.payType == 5" size="info">微信内付</el-link>
            <el-link v-if="s.row.payType == 6" size="info">预充或测试</el-link>
            <el-link v-if="s.row.payType == 7" size="info">支付宝当面付</el-link>
            <el-link v-if="s.row.payType == 9" size="info">悦浮支付宝</el-link>
            <el-link v-if="s.row.payType == 10" size="info">电商通扫码</el-link>
            <el-link v-if="s.row.payType == 11" size="info">淘宝直跳</el-link>
            <el-link v-if="s.row.payType == 12" size="info">支付宝商城网站</el-link>
            <el-link v-if="s.row.payType == 13" size="info">支付宝小钱袋</el-link>
            <el-link v-if="s.row.payType == 14" size="info">支付宝预授权</el-link>
            <el-link v-if="s.row.payType == 15" size="info">微信收款单</el-link>
            <el-link v-if="s.row.payType == 16" size="info">支付宝手机网站</el-link>
            <el-link v-if="s.row.payType == 18" size="info">小荷包客服</el-link>
            <el-link v-if="s.row.payType == 19" size="info">微信APP-IOS单端</el-link>
            <el-link v-if="s.row.payType == 20" size="info">拉卡拉-微信</el-link>
            <el-link v-if="s.row.payType > 1000" size="info">自定义通道:{{s.row.payType}}</el-link>
          </template>
        </el-table-column>
        <el-table-column label="总订单/成交订单">
          <template slot-scope="s">
            <el-link style="color: green; margin-right: 5px; font-size: 14px">{{
              s.row.order_conut
            }}/{{s.row.success_order_conut}}</el-link>
          </template>
        </el-table-column>
        <el-table-column label="收入" width="120px">
          <template slot-scope="s">
            <el-link style="color: orange; margin-right: 5px; font-size: 14px">￥{{ parseFloat(s.row.total_amonut).toFixed(2)}}</el-link>
          </template>
        </el-table-column>
        <el-table-column label="成功率">
          <template slot-scope="s">
            <el-link style="color: red; margin-right: 5px; font-size: 14px">{{s.row.accuracy}}</el-link>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="page-box">
        <el-pagination background layout="total, prev, pager, next, sizes" :current-page.sync="p.page" :page-size.sync="p.limit" :total="dataCount" :page-sizes="[1, 10, 20, 30, 40, 50, 100]" @current-change="f5(true)" @size-change="f5(true)">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['params'],
  data() {
    return {
      p: {
        // 查询参数
        mchId: this.params.mchId || '',
        codeMchId: this.params.codeMchId || '',
        type: 1,
        page: 1,
        limit: 10,
      },
      title: "切换码队",
      userDialogVisible: false,
      dataCount: 0,
      dataList: [],
      multipleSelection: null,
      switchMchId: null,
      codeMchName: null,
      conditionsKey: 0,
    };
  },
  methods: {
    // 数据刷新
    f5: function () {
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      this.sa.ajax(
        "/v1/dataAnalysis/channelStatistic",
        this.p,
        function (res) {
          this.dataList = res.data.dataList; // 数据
          this.dataCount = res.data.dataCount; // 分页
        }.bind(this),
        defaultCfg
      );
    },
    update: function (mchId, codeMchId) {
      this.p.mchId = mchId || '';
      this.p.codeMchId = codeMchId || '';
      this.f5();
    }
  },
  created: function () {
    this.f5();
  },
};
</script>

<style></style>
